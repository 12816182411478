<template>
  <md-card>
    <md-card-header :data-background-color="getTheme">
      <h4 class="title">{{ $t("template.templateForm.titleFormCreate") }}</h4>
    </md-card-header>
    <md-card-content>
      <div class="container-fluid">
        <v-form ref="form">
          <div class="row">
            <div class="col-md-4 col-12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <TGCTextField
                    :label="$t('template.templateForm.name')"
                    v-model="form.name"
                    :rules="[
                      rules.required,
                      (value) => rules.minLength(value, 0),
                      (value) => rules.maxLength(value, 100),
                    ]"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.templateForm.name") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-md-4 col-12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <v-textarea
                    outlined
                    name="input-7-4"
                    :label="$t('template.templateForm.description')"
                    v-model="form.description"
                  ></v-textarea>
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.templateForm.description") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <div class="d-flex justify-content-center">
                    <v-switch
                      v-model="form.withChannelsAndListeners"
                      :label="`${
                        form.withChannelsAndListeners
                          ? $t('template.templateForm.withChannels')
                          : $t('template.templateForm.withoutChannels')
                      }`"
                    ></v-switch>
                  </div>
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.templateForm.withChannels") }}</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </v-form>
        <div class="row">
          <div class="col-12 text-right">
            <tgl-save-button
              ref="saveBtn"
              :entity="entity"
              :dispatch="entity.dispatch"
              :action="editMode ? 'editado' : 'creado'"
              :redirect="entity.redirect"
              :validateForm="handleSubmit"
            ></tgl-save-button>
          </div>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
import { mapGetters } from "vuex";
import TglSaveButton from "@/components/UI/TglSaveButton.vue";
import TGCTextField from "@/components/UI/TGCTextField";

import form from "@/mixins/form";
export default {
  components: {
    TglSaveButton,
    TGCTextField,
  },
  name: "NewTemplateDashboard",
  mixins: [form],
  props: {
    dataBackgroundColor: {
      type: String,
      default: "ow",
    },
  },
  data: () => ({
    form: {
      name: "",
      description: "",
      withChannelsAndListeners: false,
    },
    editMode: false,
  }),
  mounted() {},
  computed: {
    ...mapGetters(["getTheme"]),
    entity() {
      let params = {
        device: this.$route.params.device,
        template: {
          device: this.$route.params.device,
          name: this.form.name,
          description: this.form.description,
          withChannelsAndListeners: this.form.withChannelsAndListeners,
        },
      };
      return {
        name: "plantilla",
        params: params,
        dispatch: "template/createTemplate",
        redirect: { name: "templates" },
      };
    },
  },
  methods: {
    handleSubmit() {
      return this.$refs.form.validate();
    },
    createTemplate(stay) {
      this.$store
        .dispatch("template/createTemplate", {
          device: this.$route.params.device,
          template: {
            device: this.$route.params.device,
            name: this.form.name,
            description: this.form.description,
            withChannelsAndListeners: this.form.withChannelsAndListeners,
          },
        })
        .then((template) => {
          if (template) this.$toast.success(this.$t("toast.createSuccess"));
          this.handleNextAction(stay, template.id);
        })
        .catch((err) => {
          this.$toast.error("algo ocurrio");
          console.error(err);
        });
    },
    goBackWithDelay() {
      setTimeout(() => {
        this.$router.push({
          name: "templates",
        });
      }, 1000);
    },
    handleNextAction(stay, dashboard) {
      switch (stay) {
        case "createAnother":
          this.cleanForm();
          this.$refs.form.resetValidation();
          this.form.role_id = 1;
          break;
        case "stay":
          this.editMode = true;
          if (this.$route.name !== "dashboardEdit") {
            this.$router.push({
              name: "dashboardEdit",
              params: { ...this.$route.params, dashboard },
            });
          }
          break;
        case "goBack":
          this.goBackWithDelay();
          break;
      }
    },
  },
};
</script>