var render = function render(){var _vm=this,_c=_vm._self._c;return _c('md-card',[_c('md-card-header',{attrs:{"data-background-color":_vm.getTheme}},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("template.templateForm.titleFormCreate")))])]),_c('md-card-content',[_c('div',{staticClass:"container-fluid"},[_c('v-form',{ref:"form"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 col-12"},[_c('div',{staticClass:"row d-flex align-center m-0 mb-5"},[_c('div',{staticClass:"col col-10"},[_c('TGCTextField',{attrs:{"label":_vm.$t('template.templateForm.name'),"rules":[
                    _vm.rules.required,
                    (value) => _vm.rules.minLength(value, 0),
                    (value) => _vm.rules.maxLength(value, 100),
                  ]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"col col-2",attrs:{"color":"primary","size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("tooltips.templateForm.name")))])])],1)]),_c('div',{staticClass:"col-md-4 col-12"},[_c('div',{staticClass:"row d-flex align-center m-0 mb-5"},[_c('div',{staticClass:"col col-10"},[_c('v-textarea',{attrs:{"outlined":"","name":"input-7-4","label":_vm.$t('template.templateForm.description')},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"col col-2",attrs:{"color":"primary","size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("tooltips.templateForm.description")))])])],1)]),_c('div',{staticClass:"col-lg-4 col-12"},[_c('div',{staticClass:"row d-flex align-center m-0 mb-5"},[_c('div',{staticClass:"col col-10"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('v-switch',{attrs:{"label":`${
                      _vm.form.withChannelsAndListeners
                        ? _vm.$t('template.templateForm.withChannels')
                        : _vm.$t('template.templateForm.withoutChannels')
                    }`},model:{value:(_vm.form.withChannelsAndListeners),callback:function ($$v) {_vm.$set(_vm.form, "withChannelsAndListeners", $$v)},expression:"form.withChannelsAndListeners"}})],1)]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"col col-2",attrs:{"color":"primary","size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("tooltips.templateForm.withChannels")))])])],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-right"},[_c('tgl-save-button',{ref:"saveBtn",attrs:{"entity":_vm.entity,"dispatch":_vm.entity.dispatch,"action":_vm.editMode ? 'editado' : 'creado',"redirect":_vm.entity.redirect,"validateForm":_vm.handleSubmit}})],1)])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }